<template>
  <div class="my-10">
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md5>
            <v-card class="elevation-12">
              <v-alert dense outlined :type="typeAlert" v-if="errorMessage != ''">
                {{ errorMessage }}
              </v-alert>
              <v-toolbar dark color="light-blue accent-2">
                <v-toolbar-title>Login</v-toolbar-title>
              </v-toolbar>
              <v-form ref="form" @submit.prevent="onSubmit" lazy-validation>
                <!-- <v-card-text>
                  <v-text-field prepend-icon="mdi-account" name="email" label="Email" type="text" v-model="account.email" :rules="nameRules"></v-text-field>
                  <v-text-field prepend-icon="mdi-lock" name="password" label="Password" type="password" v-model="account.password" :rules="nameRules"></v-text-field>
                </v-card-text> -->
                <v-row>
                  <v-col cols="3" class="">
                    <!-- <v-icon  color="blue darken-2" size="100">
                      mdi-qrcode-scan
                    </v-icon> -->
                    <v-btn icon color="blue" width="100" height="100" class="ma-4" to="/login-student">
                      <v-icon size="80">mdi-qrcode-scan</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="9">
                    <v-text-field prepend-icon="mdi-account" name="email" label="Email" type="text" v-model="account.email" :rules="nameRules"></v-text-field>
                    <v-text-field prepend-icon="mdi-lock" name="password" label="Password" type="password" v-model="account.password" :rules="nameRules"></v-text-field>
                  </v-col>
                </v-row>
                <v-spacer></v-spacer>
                <v-row>
                  <v-col class="col-3"></v-col>
                  <v-col class="col-6">
                    <p @click="$router.push({name: 'forgotpassword'})" class="c-pointer px-4">Forgot your password</p>
                  </v-col>
                  <v-col><v-btn color="light-blue accent-2" dark type="submit">Login</v-btn></v-col>
                </v-row>
              </v-form>
              <v-divider class="my-3"></v-divider>   
              <div class="text-center">
                  Or
                </div>
              <v-btn elevation="4" class="mt-2" outlined color="blue-grey darken-2" block @click="LoginWithGoogle">
                Login with Google  
                <img width="30px" src="../assets/admin/buscar.svg" class="ml-1">
              </v-btn>
              <div class="loginClever">
                <a href="https://clever.com/oauth/authorize?response_type=code&redirect_uri=https://claseleelee.com/oauth2&client_id=70d77ad860ae2e735c9d">Login with Clever</a>
              </div>
              <!-- <v-divider class="my-3"></v-divider>  
              <div class="text-center">
                or
              </div>
              <v-hover v-slot="{ hover }">
              <v-card max-width="300" class="mx-auto my-3 text-center" :elevation="hover ? 12 : 2" href="/login-student">
                <v-card-text>
                  <v-icon size="80" color="green darken-2">mdi-qrcode-scan</v-icon>
                  <p style="display: inline-block;" class="ma-5 text-h6">QR Code</p>
                </v-card-text>
              </v-card>
              </v-hover> -->
            </v-card>

          </v-flex>
        </v-layout>
      </v-container>
  </div>
</template>

<script>
import { auth, functions } from '../plugins/firebase'

export default {
  name: 'Login',
  data: () => ({
    account: {
      email: '',
      password: ''
    },
    nameRules: [
      v => (v && v.length >= 2) || 'Minimum length is 2 characters',
      v => (v && v.length <= 30) || 'Name must be less than 30 characters'
    ],
    errorMessage: '',
    typeAlert: ''
  }),
  created () {
    console.log(this.$store.state.user)
  },
  methods: {
    onSubmit () {
    //window.history.forward(1);
      console.log(this.account)
      if (!this.$refs.form.validate()) return;
      this.$store.dispatch('login', this.account).then(() => {
        console.log(this.$store.state.user.role)
        location.reload();
        // if (this.$store.state.user.role == 'schoolTeacher') {
        //   location.href = "schoolTeacher";
        // }else{
        //   location.reload();
        // }
      }).catch(error => {
        console.log(error);
        if (Object.prototype.hasOwnProperty.call(error, 'message')) {
          this.MessageAlert(error.message, 'error');
        }else
          this.MessageAlert(error, 'error');
      });
    },
    LoginWithGoogle () {
      let localStore =this.$store;
      let provider = new auth.GoogleAuthProvider();
      auth().signInWithPopup(provider).then(
        function (result) {
          console.log(result)
              // var token = result.credential.idToken;
              var user = result.user;

              //this is what you need
              var isNewUser = result.additionalUserInfo.isNewUser;
              if (isNewUser) {
                console.log('usuario eliminado')
                  //delete the created user
                  result.user.delete();
              } else {
                  // your sign in flow
                  console.log('user ' + user.email + ' does exist!');
                  localStore.dispatch('loginGoogle').then(() => {
                    if (localStore.state.user.role == 'schoolTeacher') {
                      location.href = "schoolTeacher";
                    }else{
                      location.reload();
                    }
                  }).catch(error => {
                    console.log(error);
                    this.errorMessage = error;
                  });
              }
        }).catch(function (error) {
          console.log(error)
        });
    },
    MessageAlert ( message, type) {
      this.typeAlert = type;
      this.errorMessage = message;
      setTimeout(() => this.errorMessage = '', 4000);
    },
    async verifyVersion(){
        //console.log(process.env.VUE_APP_VERSION)
        let localVersion =process.env.VUE_APP_VERSION;
        const getVersion = functions.httpsCallable('getVersion')
        let response =await getVersion()
        if (response.data != localVersion) {
          //este proceso es solo para recargar una vez al dia
          var now = new Date();
          let memoria = localStorage.getItem('update');
          now.setHours(0,0,0,0);
          console.log(memoria)
          console.log(now)
          if (memoria != null) {
            let memoriaDate=new Date(memoria);
            if (now.getTime() > memoriaDate.getTime()) {
              console.log("necesita actualizar")
              sessionStorage.clear()
              localStorage.clear()
              var datenow = new Date();
              datenow.setHours(0,0,0,0);
              localStorage.setItem('update',datenow )
              location.reload();
            }else{
               console.log("recargo el mismo dia")
              return;
            }
          }else{
              var datenow = new Date();
              datenow.setHours(0,0,0,0);
              localStorage.setItem('update',datenow )
              location.reload();
          } 
          
          caches.keys().then(keys => {
            keys.forEach(key => caches.delete(key))
          })
          indexedDB.databases().then(dbs => {
            dbs.forEach(db => indexedDB.deleteDatabase(db.name))
          })
     
          indexedDB.databases().then(dbs => { dbs.forEach(db => { if(db.name!="firebaseLocalStorageDb"){indexedDB.deleteDatabase(db.name)} }) })
        }
      }
  },
  mounted () {
    this.verifyVersion();
    // ---------------aqui empieza el antiguo------
    // var now = new Date();
    // let memoria = localStorage.getItem('update');
    // now.setHours(0,0,0,0);
    // console.log(memoria)
    // console.log(now)
    // if (memoria != null) {
    //   let memoriaDate=new Date(memoria);
    //   if (now.getTime() > memoriaDate.getTime()) {
    //     console.log("necesita actualizar")
    //   }else{
    //      console.log("actualizado")
    //     return;
    //   }
    // }
    // sessionStorage.clear()
    // localStorage.clear()
    // caches.keys().then(keys => {
    //   keys.forEach(key => caches.delete(key))
    // })
    // indexedDB.databases().then(dbs => {
    //   dbs.forEach(db => indexedDB.deleteDatabase(db.name))
    // })
    // var datenow = new Date();
    // datenow.setHours(0,0,0,0);
    // localStorage.setItem('update',datenow )
    // location.reload();
    // console.log("paso")
    // ---------------hasta aqui el antiguo------
    // const getVersion = functions.httpsCallable('getVersion') // loginClever    --- loginCleverDemo
    // getVersion().then((result)=> {
    //   if (result.data != process.env.VUE_APP_VERSION) {
    //     console.log('entro')
    // window.history.forward(1);
    //     sessionStorage.clear()
    //     localStorage.clear()
    //     caches.keys().then(keys => {
    //       keys.forEach(key => caches.delete(key))
    //     })

    //     indexedDB.databases().then(dbs => {
    //       dbs.forEach(db => indexedDB.deleteDatabase(db.name))
    //     })
    //     var datenow = new Date();
    //     datenow.setHours(0,0,0,0);
    //     localStorage.setItem('update',datenow )
    //     location.reload();
    //   }
    // }) 
  }
}
</script>
<style>
  .c-pointer{
    cursor: pointer;
  }
  .loginClever{
    text-align: center;
    margin: 15px;
    font-size: 1.1em;
  }
  .loginClever a{
    text-decoration: none;
  }
</style>